<template>
  <!-- <div style="min-height: 4px">
      <v-progress-linear
        v-model="value"
        :active="show"
        :indeterminate="query"
        :query="true"
      ></v-progress-linear>
    </div> -->
  <div>
    <div class="event-details">
      <v-btn
        class="ma-2"
        style="position: fixed; z-index: 2"
        fab
        dark
        color="#efce6a"
        @click="$router.push({ path: '/events?back=1' })"
      >
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <div class="cover-img">
        <v-img :src="eventDetail.s3FileUrl"></v-img>
        <div class="cover-img-title">
          <div class="cover-img-title-content text-h3 text-md-h1">
            {{ eventDetail.title }}
          </div>
        </div>
      </div>
      <div class="details-info">
        <div class="details-info-name">
          <div class="description-text">
            <p>
              {{ eventDetail.description }}
            </p>

            <div class="d-flex flex-column-reverse flex-md-row">
              <div class="date-time mt-3">
                <v-icon class="mr-2">mdi-calendar-range </v-icon>
                {{ dayOfDate }}
                {{ dayAndDate }} To {{ endDayOfDate }} {{ endDate }}
                <strong class="ml-2">{{ eventDetail.time }}</strong
                ><br />
                <v-icon class="mr-2">mdi-calendar-range </v-icon>CutOff Date :
                {{ cutOffDate }}
              </div>
              <div class="ml-auto"></div>
              <v-btn
                class="subscribe"
                v-if="roleName !== 'Agent'"
                @click="dialog = true"
                :disabled="accessImportEnable"
                title="Import Event Attendees"
              >
                <v-icon class="mr-3">mdi-upload</v-icon
                ><span class="d-md-none">Import Event Attendees</span> </v-btn
              >&nbsp;
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn color="primary" dark >
                  Dropdown
                </v-btn> -->
                  <v-btn
                    v-if="roleName !== 'Agent'"
                    class="subscribe"
                    text
                    elevation="0"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    title="Export Report"
                  >
                    <v-icon>mdi-download</v-icon
                    ><span class="d-md-none">Export Report</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in buttonItems"
                    :key="index"
                    link
                    :disabled="computedAccess(item.computed)"
                    @click="methodsForButtons(item.method, index)"
                  >
                    <v-list-item-title>
                      {{ item.title }} &nbsp;&nbsp;
                      <v-icon class="float-right">{{ item.icon }} </v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </v-list> </v-menu
              >&nbsp;
              <v-btn
                :disabled="
                  (expireEvent && !eventDetail.isSubscribeAllowed) ||
                  !eventDetail.isActive ||
                  accessEnableForSubscribe
                "
                v-if="
                  !eventDetail.isSubscribed || eventDetail.isSubscribeAllowed
                "
                class="float-right subscribe"
                title="To subscribe for this event click on Subscribe button."
                @click="
                  $emit('subscribe', {
                    isSubscribe: false,
                    eventId: Number(eventDetail.id),
                    isMultiGift: eventDetail.isMultiGift,
                    isCodeRequired: eventDetail.isCodeRequired,
                    isGiftAvailable: eventDetail.isGiftAvailable,
                  })
                "
              >
                <v-icon class="mr-3">mdi-bell-ring </v-icon>
                <span class="d-md-none">Subscribe</span>
              </v-btn>
              <v-btn
                :disabled="
                  (expireEvent && eventDetail.isSubscribeAllowed) ||
                  requestForEnableSubscribe ||
                  eventDetail.isRequestedUnsubscribed
                "
                v-else-if="eventDetail.isSubscribed"
                class="subscribe"
                title="Unsubscribe"
                @click="requestForUnsubscribe()"
              >
                <v-icon class="mr-3">mdi-thumb-up </v-icon
                ><span class="d-md-none">{{
                  eventDetail.isRequestedUnsubscribed
                    ? "Request is pending"
                    : "Request for unsubscribe"
                }}</span> </v-btn
              >&nbsp;
              <v-btn
                :disabled="
                  requestForEnableSubscribe ||
                  eventDetail.isRequestedSubscribed ||
                  eventDetail.isSubscribeAllowed ||
                  eventDetail.isSubscribe
                "
                v-if="expireEvent"
                class="subscribe"
                title="Request for Subscribe"
                @click="requestForSubscribe()"
              >
                <v-icon class="mr-3">mdi-account-key</v-icon
                ><span class="d-md-none">Request for Subscribe</span>
              </v-btn>
            </div>
          </div>
          <v-divider class="my-5" dark></v-divider>
          <div class="d-flex flex-column-reverse flex-md-row">
            <div class="hotel-name">
              <div>
                <v-icon class="mr-3" dark>mdi-office-building </v-icon>
                {{ eventDetail.hotelName }}
                <p class="address">
                  &nbsp;&nbsp; {{ " " + eventDetail.hotelAddress }}-{{
                    eventDetail.hotelContact
                  }}
                </p>
              </div>
              <div class="address my-2 d-block">
                <v-icon class="mr-3">mdi-web </v-icon
                ><a :href="`//${eventDetail.hotelWebsite}`" target="_blank">{{
                  eventDetail.hotelWebsite || "www.google.com"
                }}</a>
              </div>
              <div class="address my-4">
                <v-icon class="mr-3">mdi-map-marker </v-icon>
                <a
                  :href="`https://www.google.com/maps/search/?api=1&query=${eventDetail.hotelLatitude},${eventDetail.hotelLongitude}&query_place_id=${eventDetail.hotelGooglePlaceId}`"
                  target="_blank"
                  >{{ eventDetail.hotelAddress }}</a
                >
              </div>
            </div>
          </div>
          <div class="description-text">
            <v-divider class="my-5" dark></v-divider>
          </div>
          <div
            class="event-box-list"
            v-if="eventDetail?.eventNearbyHotels?.length != 0"
          >
            <v-row class="my-0">
              <v-col cols="12">
                <h3>
                  <v-icon dark>mdi-office-building</v-icon> Near By Hotels
                </h3>
                <v-divider class="my-3" dark></v-divider>
                <div class="event-text">
                  <br />
                  <ul class="d-flex flex-wrap">
                    <li
                      v-for="hotel in eventDetail.eventNearbyHotels"
                      :key="hotel.id"
                      class="liForHotels"
                    >
                      <div class="d-flex flex-column-reverse flex-md-row">
                        <div class="hotel-name">
                          <span>
                            <v-icon class="mr-3">mdi-office-building </v-icon>
                            {{ hotel.hotelName }}
                          </span>
                          <div class="address my-2 d-block">
                            <v-icon class="mr-3">mdi-web </v-icon
                            ><a
                              :href="`//${hotel.hotelWebsite}`"
                              target="_blank"
                              >{{ hotel.hotelWebsite || "www.google.com" }}</a
                            >
                          </div>
                          <div class="address my-4">
                            <v-icon class="mr-3">mdi-map-marker </v-icon>
                            <a
                              :href="`https://www.google.com/maps/search/?api=1&query=${hotel.hotelLatitude},${hotel.hotelLongitude}&query_place_id=${hotel.hotelGooglePlaceId}`"
                              target="_blank"
                              >{{ hotel.hotelAddress }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </div>
          <!-- <div class="date-time mt-3">
          <v-icon class="mr-2">mdi-calendar-range </v-icon> {{ dayOfDate }}
          {{ dayAndDate }}
          <strong class="ml-2">12pm To 3pm</strong>
        </div> -->
        </div>
        <div
          class="description-text"
          v-if="eventDetail?.eventNearbyHotels?.length != 0"
        >
          <v-divider class="my-5" dark></v-divider>
        </div>
        <div class="event-box-list">
          <v-row class="my-0">
            <v-col
              cols="12"
              md="4"
              v-if="eventDetail?.eventActivities?.length != 0"
            >
              <div class="event-text">
                <h3><v-icon dark>mdi-account-group</v-icon> Event Activity</h3>
                <v-divider class="my-3" dark></v-divider>
                <ul v-for="activity in sortActivity" :key="activity.id">
                  <li>
                    <span>{{ activity.eventActivityName }}</span>
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="eventDetail?.eventItineraries?.length != 0"
            >
              <div class="event-text">
                <h3>
                  <v-icon dark>mdi-map-marker-path</v-icon> Event Itinerary
                </h3>
                <v-divider class="my-3" dark></v-divider>
                <ul
                  v-for="itinerary in eventDetail.eventItineraries"
                  :key="itinerary.id"
                >
                  <li>
                    <span>{{ itinerary.eventItineraryName }}</span>
                    <div>
                      <v-btn
                        small
                        @click="downloadItinerary(itinerary)"
                        :disabled="accessDownloadItinerary"
                      >
                        <v-icon icon>mdi-download </v-icon>
                      </v-btn>
                    </div>
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="
                eventDetail?.eventGifts?.length != 0 &&
                eventDetail.isGiftAvailable
              "
            >
              <div class="event-text">
                <h3><v-icon dark>mdi-gift</v-icon> Event Gifts</h3>
                <v-divider class="my-3" dark></v-divider>
                <ul v-for="gift in eventDetail.eventGifts" :key="gift.id">
                  <li style="justify-content: flex-start">
                    <div>
                      <v-list-item-avatar tile size="50" color="grey">
                        <img
                          :src="gift.s3FileUrl"
                          style="border: 1px solid white; cursor: pointer"
                          @click="
                            imageSource = gift.s3FileUrl;
                            expandedGift = true;
                          "
                        />
                        <expandable-image
                          @closeZoom="expandedGift = false"
                          v-if="expandedGift === true"
                          class="image"
                          :src="imageSource"
                          alt="Imag not Found"
                          aspect-ratio="1"
                        ></expandable-image>
                      </v-list-item-avatar>
                    </div>
                    <p style="align-self: center">{{ gift.giftName }}</p>
                  </li>
                </ul>
              </div>
            </v-col>
            <template v-if="eventDetail.isGuestSpeakerAvailable">
              <v-col cols="12">
                <div class="event-text">
                  <h3><v-icon dark>mdi-account-tie</v-icon>Guest Speakers</h3>
                  <v-divider class="my-1" dark></v-divider>

                  <!-- <ul v-for="gift in eventDetail.eventGifts" :key="gift.id">
                  <li>
                    <span>{{ gift.giftName }}</span>
                  </li>
                </ul> -->
                </div>
              </v-col>

              <v-col
                cols="12"
                md="4"
                v-for="(speaker, ind) in eventDetail.eventGuestSpeakers"
                :key="speaker.id"
              >
                <v-card class="mx-2 guestSpeakerClass" outlined style="">
                  <v-list-item three-line>
                    <v-list-item-avatar tile size="50" color="grey"
                      ><img
                        :src="speaker.s3FileUrl"
                        style="border: 1px solid white; cursor: pointer"
                        @click="
                          imageSource = speaker.s3FileUrl;
                          expanded = true;
                        " /><expandable-image
                        @closeZoom="expanded = false"
                        v-if="expanded === true"
                        class="image"
                        :src="imageSource"
                        alt="Imag not Found"
                        aspect-ratio="1"
                      ></expandable-image
                    ></v-list-item-avatar>

                    <v-list-item-content class="d-flex">
                      <v-list-item-title style="color: white">{{
                        speaker.speakerName
                      }}</v-list-item-title>
                      <!-- <v-list-item-subtitle
                      >Greyhound divisely hello coldly
                      fonwderfully</v-list-item-subtitle
                    > -->
                    </v-list-item-content>
                    <v-card-actions>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- <v-btn color="primary" dark >
                  Dropdown
                </v-btn> -->
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            rounded
                            text
                            @click="
                              guestItemDownloadList(
                                eventDetail.eventGuestSpeakers,
                                ind
                              )
                            "
                            style="background-color: #f0ce6a; color: white"
                            ><v-icon icon>mdi-download </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(items, index) in getDownloadList.pdf"
                            :key="`pdf${index}`"
                            link
                          >
                            <v-list-item-title
                              @click="
                                downloadGuestSpeakerMedia(
                                  items.id,
                                  items.virtualFileName,
                                  items.originalFileName,
                                  'pdf'
                                )
                              "
                            >
                              <template>
                                <div>
                                  <v-icon>mdi-file-pdf-box </v-icon>
                                  {{ items.originalFileName }}

                                  &nbsp;&nbsp;

                                  <v-icon class="float-right"
                                    >mdi-download
                                  </v-icon>
                                </div>
                              </template>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-for="(items, index) in getDownloadList.image"
                            :key="`image${index}`"
                            link
                          >
                            <v-list-item-title
                              @click="
                                downloadGuestSpeakerMedia(
                                  items.id,
                                  items.virtualFileName,
                                  items.originalFileName,
                                  'image'
                                )
                              "
                            >
                              <template>
                                <div>
                                  <v-icon>mdi-image-area </v-icon>
                                  {{ items.originalFileName }}
                                  &nbsp;&nbsp;

                                  <v-icon class="float-right"
                                    >mdi-download
                                  </v-icon>
                                </div>
                              </template>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-for="(items, index) in getDownloadList.videos"
                            :key="`video${index}`"
                            link
                          >
                            <v-list-item-title
                              @click="
                                downloadGuestSpeakerMedia(
                                  items.id,
                                  items.virtualFileName,
                                  items.originalFileName,
                                  'video'
                                )
                              "
                            >
                              <template>
                                <div>
                                  <v-icon>mdi-multimedia </v-icon>
                                  {{ items.originalFileName }}
                                  &nbsp;&nbsp;

                                  <v-icon class="float-right"
                                    >mdi-download
                                  </v-icon>
                                </div>
                              </template>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-card-actions>
                  </v-list-item>
                </v-card>
              </v-col>
            </template>
            <!-- <v-col cols="12" class="">
              <v-card class="pa-5">
                <h3>Upload Video / Images</h3>
                <v-divider class="my-3"></v-divider>
                <v-row>
                  <v-col clos="12" sm="12">
                    <div class="video-photo">
                      <div class="profile-pic-div">
                        <img :src="preview" id="photo" />

                        <input
                          type="file"
                          id="file"
                          @change="previewImage($event)"
                          accept="image/*"
                        />

                        <label for="file" id="uploadBtn"
                          >Upload Video / Images</label
                        >
                      </div>
                    </div>
                  </v-col>
                  <v-col clos="12" sm="12">
                    <div>View</div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col> -->
          </v-row>
        </div>
      </div>
    </div>

    <v-col cols="12" class="">
      <v-card class="pa-5 uploding-box-file">
        <validation-observer ref="FormObserver" v-slot="{ handleSubmit }">
          <v-form
            class="newClass"
            @submit.prevent="handleSubmit(uploadEventMedia)"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Media"
              :rules="{ required: true }"
              mode="lazy"
            >
              <v-col cols="12" class="uploadMedia">
                <h3 class="uploadMediaTitle">Upload Video / Images</h3>
                <div class="fileInput1">
                  <v-file-input
                    type="file"
                    label="Upload Image/Video"
                    placeholder="Upload Image/Video"
                    outlined
                    v-model="files"
                    @change="previewImage($event)"
                    append-icon="mdi-attachment"
                    prepend-icon=""
                    multiple
                    accept="video/*,image/*"
                    ref="inputFile"
                    :hide-details="!errors.length"
                    :error-messages="errors"
                  />
                </div>
                <div class="ml-auto text-right"></div>

                <v-btn
                  class="theme-btn-gold"
                  type="submit"
                  :disabled="accessUploadEnable"
                >
                  <v-icon class="mr-3">mdi-cloud-upload </v-icon>Upload
                </v-btn>
              </v-col>
            </validation-provider>
          </v-form></validation-observer
        >
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col clos="12" sm="12">
            <div class="">
              <h4>Uploading</h4>
              <ul class="d-flex" style="flex-wrap: wrap">
                <li
                  style="list-style-type: none"
                  v-for="(image, index) in uploadImage"
                  :key="image.origionalFileName"
                >
                  <!-- <div class="file-icon">
                    <v-icon>mdi-folder-multiple-image</v-icon>
                  </div> -->
                  <v-col>
                    <div class="file-name-path">
                      <img
                        :src="`data:image/jpeg;base64,${image.eventMedia}`"
                        alt=""
                        width="150"
                        height="150"
                      />
                      <v-icon
                        class="closeIconClass"
                        @click="removeMedia(uploadImage, index)"
                        >mdi-close
                      </v-icon>

                      <!-- <span class="truncate">
                      {{ image.origionalFileName }}
                    </span> -->
                    </div>
                  </v-col>
                </li>
                <li
                  style="list-style-type: none"
                  v-for="(video, index) in uploadVideo"
                  :key="video.origionalFileName"
                >
                  <v-col>
                    <div class="file-name-path">
                      <video width="150" height="150" controls style="">
                        <source
                          :src="`data:image/jpeg;base64,${video.eventMedia}`"
                        />
                        <source src="movie.ogg" type="video/ogg" />
                      </video>
                      <v-icon
                        class="closeIconClass"
                        @click="removeMedia(uploadVideo, index)"
                        >mdi-close
                      </v-icon>
                    </div>
                  </v-col>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col clos="12" sm="12">
            <div class="tab-content-images" v-if="eventDetail.eventMedia">
              <template>
                <v-card elevation="0">
                  <v-tabs color="accent-4">
                    <v-tab href="#tab-1">Images</v-tab>
                    <v-tab href="#tab-2">Videos</v-tab>

                    <v-tab-item :value="'tab-' + 1">
                      <v-container fluid>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="2"
                            v-for="(img, ind) in eventDetail.eventMedia
                              .eventPhotos"
                            :key="`'img'${ind}`"
                          >
                            <div class="img-overly">
                              <v-img :src="img.s3Url" />
                              <expandable-image
                                @closeZoom="expandedimage = false"
                                v-if="expandedimage === true"
                                class="image"
                                :src="imageSource"
                                aspect-ratio="1"
                              ></expandable-image>

                              <!-- <v-img
                                :src="`https://picsum.photos/500/300?image=${
                                  i * n * 5 + 10
                                }`"
                                :lazy-src="`https://picsum.photos/10/6?image=${
                                  i * n * 5 + 10
                                }`"
                                aspect-ratio="1"
                              ></v-img> -->
                              <div class="img-overly-box">
                                <v-btn
                                  icon
                                  rounded
                                  text
                                  class="mr-2"
                                  @click="
                                    expandedimage = true;
                                    imageSource = img.s3Url;
                                  "
                                >
                                  <v-icon>mdi-magnify-plus-outline </v-icon>
                                </v-btn>
                                <v-btn
                                  icon
                                  :disabled="accessDeleteMedia"
                                  rounded
                                  text
                                  class=""
                                  @click="confimationDelete(img.id, true)"
                                  v-if="roleName !== 'Agent'"
                                >
                                  <v-icon> mdi-delete </v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab-item :value="'tab-' + 2">
                      <v-container fluid>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="2"
                            v-for="(vido, ind) in eventDetail.eventMedia
                              .eventVideos"
                            :key="`'video'${ind}`"
                          >
                            <div class="img-overly">
                              <video width="100%" height="100%">
                                <source :src="vido.s3Url" />
                                <source src="movie.ogg" type="video/ogg" />
                              </video>
                              <expandable-video
                                @closeZoom="expanded = false"
                                v-if="expanded === true"
                                class="image"
                                :src="imageSource"
                                :index="ind"
                                :imgind="imgindex"
                                aspect-ratio="1"
                              ></expandable-video>
                              <div class="img-overly-box">
                                <v-btn
                                  icon
                                  rounded
                                  text
                                  class="mr-2"
                                  @click="
                                    expendableVedioMethod(vido.s3Url, ind)
                                  "
                                >
                                  <v-icon>mdi-magnify-plus-outline </v-icon>
                                </v-btn>
                                <v-btn
                                  :disabled="accessDeleteMedia"
                                  icon
                                  rounded
                                  text
                                  class=""
                                  @click="confimationDelete(vido.id, true)"
                                  v-if="roleName !== 'Agent'"
                                >
                                  <v-icon> mdi-delete </v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <ConfirmationModal
        :dialog="ConfirmDialog"
        :deleteId="deleteId"
        @confirmation="deleteMedia($event)"
      />
      <progress-circular :dialog="loading" />

      <import-attendees-modal
        :dialog="dialog"
        @disableDialog="dialog = $event.value"
        :eventId="Number(eventId)"
      />
      <disclaimer
        :dialog="isDisclaimer"
        @closeDisclaimer="closeDisClaimer($event)"
      />
    </v-col>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import ExportService from "@/services/ExportService";
import ProgressCircular from "../components/ProgressCircular.vue";
import {
  toMMDDYYYY,
  dateFormat,
  toBase64,
  byteToFileReader,
  actionEnable,
} from "@/utils/helpers";
import ExpandableImage from "./ExpandableImage.vue";
import ImportAttendeesModal from "./Modals/ImportAttendeesModal.vue";
import ConfirmationModal from "./Modals/ConfirmationModal.vue";
import ExpandableVideo from "./ExpandableVideo.vue";
import Disclaimer from "./Disclaimer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  data() {
    return {
      imgindex: "",
      imageSource: "",
      files: [],
      endDate: "",
      userId: "",
      dialog: false,
      ConfirmDialog: false,
      isDisclaimer: true,
      disClaimerId: 0,
      isDelete: false,
      roleName: "",
      loading: false,
      uploadMediaList: [],
      uploadImage: [],
      uploadVideo: [],
      eventDetail: [],
      dayAndDate: "",
      dayOfDate: "",
      endDayOfDate: "",
      expanded: false,
      expandedGift: false,
      expandedimage: false,
      preview: [],
      deleteId: 0,
      backgroundOrigionalFileName: "",
      backgroundImage: "",
      getDownloadList: [],
      buttonItems: [
        {
          title: "Event Attendees",
          icon: "mdi-cloud-download",
          method: "exportEventAttendees",
          computed: "accessEnableExportEventAttendees",
        },
        {
          title: "Gift Selection",
          icon: "mdi-cloud-download",
          method: "exportQualifiedAttendees",
          computed: "accessEnableExportGiftSelection",
        },
        {
          title: "Event Detail",
          icon: "mdi-cloud-download",
          method: "exportEventDetails",
          computed: "accessEnableExportEventDetails",
        },
      ],
      // guestItemDownloadList: [],
    };
  },
  // watch: {
  //   files: {
  //     handler: function () {
  //       this.files.length != 0
  //         ? ((this.uploadImage = []), (this.uploadVideo = []))
  //         : "";
  //     },
  //   },
  //   deep: true,
  // },

  components: {
    ExpandableImage,
    ImportAttendeesModal,
    ProgressCircular,
    ExpandableVideo,
    ConfirmationModal,
    Disclaimer,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    sortActivity() {
      let actvity = this.eventDetail?.eventActivities;
      return actvity?.sort((a, b) => {
        return a.orderNumber - b.orderNumber;
      });
    },
    expireEvent() {
      return this.eventDetail.registrationEndDate
        ? !(
            dateFormat(new Date(), "MM-dd-yyyy") <=
            this.eventDetail.registrationEndDate.split(" ")[0]
          )
        : false;
    },
    cutOffDate() {
      return this.eventDetail.registrationEndDate
        ? this.eventDetail.registrationEndDate
            .split(" ")[0]
            .split("-")
            .join("/")
        : "";
    },
    accessDeleteMedia() {
      return !actionEnable("Event", "DeleteMedia", 1);
    },
    accessDownloadItinerary() {
      return !actionEnable("Event", "DownloadItinerary", 1);
    },

    accessImportEnable() {
      return !actionEnable("Event", "ImportQualifiedAgents", 1);
    },
    accessUploadEnable() {
      return !actionEnable("Event", "UploadMedia", 1);
    },
    requestForEnableSubscribe() {
      return !actionEnable("Event", "RequestSubscribeUnsubscribe", 1);
    },
    accessEnableExportEventDetails() {
      return actionEnable("EventRegisteredReport", "ExportEventDetails", 1);
    },
    accessEnableExportGiftSelection() {
      return actionEnable(
        "EventRegisteredReport",
        "ExportEventAttendeesGiftSelection",
        1
      );
    },
    accessEnableExportEventAttendees() {
      return actionEnable("EventRegisteredReport", "ExportEventAttendees", 1);
    },
    accessEnableForSubscribe() {
      return !actionEnable("Event", "Subscribe", 1);
    },
  },
  async created() {
    this.userId = this.$store.state.auth.userState.user.userId;
    const setting = localStorage.getItem("disclaimerSetting");
    if (setting) {
      if (this.userId === setting.id) {
        this.isDisclaimer = setting.value;
      } else {
        this.isDisclaimer = false;
      }
    }
    this.eventId = Number(this.$route.query["eventId"]);
    // this.loading = true;
    await this.getEventDetails();
    // this.loading = false;
    this.roleName = this.$store.state.auth.userState.roleName;
  },

  beforeDestroy() {},

  methods: {
    methodsForButtons(method, index) {
      this[method](index);
    },
    computedAccess(computedProperty) {
      return !this[computedProperty];
    },

    removeMedia(mediaArray, index) {
      mediaArray.splice(index, 1);
    },
    async getEventDetails() {
      try {
        this.loading = true;
        const { data } = await EventService.getEventDetail(this.eventId);

        this.eventDetail = data.data;
        // this.guestItemDownloadList = data.data.eventGuestSpeakers.map(
        //   (item) => {
        //     return {
        //       pdfs: item.eventSpeakerMedias.eventPdf,
        //       images: item.eventSpeakerMedias.eventPhotos,
        //       videos: item.eventSpeakerMedias.eventVideos,
        //     };
        //   }
        // );
        // console.log(this.guestItemDownloadList);

        this.dayAndDate = toMMDDYYYY(this.eventDetail.startDate);
        this.endDate = toMMDDYYYY(this.eventDetail.endDate);
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const startDay = new Date(this.dayAndDate);
        const endDay = new Date(this.endDate);
        this.dayOfDate = days[startDay.getDay()];
        this.endDayOfDate = days[endDay.getDay()];
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async exportEventDetails() {
      try {
        this.loading = true;
        const response = await ExportService.exportEventDetails({
          eventId: this.eventId,
        });
        // console.log(response.status === 200);
        if (response.status === 200) {
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth() + 1;
          let yyyy = today.getFullYear();

          const fileName = "Event Details " + `${mm}-${dd}-${yyyy}`;
          byteToFileReader(response.data, fileName, "xlsx");
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }

        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 404) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Event attendees not found",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    async exportEventAttendees() {
      try {
        this.loading = true;
        const response = await ExportService.exportEventAttendees({
          eventId: this.eventId,
        });
        if (response.status === 200) {
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth() + 1;
          let yyyy = today.getFullYear();

          const fileName = "Event Attendees " + `${mm}-${dd}-${yyyy}`;
          byteToFileReader(response.data, fileName, "xlsx");
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        // console.log(error.toJSON().status);
        if (error.toJSON().status === 404) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Event attendees not found",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    async exportQualifiedAttendees() {
      try {
        this.loading = true;
        const response = await ExportService.exportQualifiedAttendees({
          eventId: this.eventId,
        });
        if (response.status === 200) {
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth() + 1;
          let yyyy = today.getFullYear();

          const fileName = "Gift Selection " + `${mm}-${dd}-${yyyy}`;
          byteToFileReader(response.data, fileName, "xlsx");
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 404) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Gift not found",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },

    async uploadEventMedia() {
      try {
        let response;
        if (this.uploadImage.length != 0 || this.uploadVideo.length != 0) {
          this.loading = true;
          response = await EventService.uploadMedia({
            eventId: this.eventId,
            images: this.uploadImage,
            videos: this.uploadVideo,
          });
        }

        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          this.uploadImage = [];
          this.uploadVideo = [];
          this.$refs.inputFile.reset();
          this.loading = false;
          this.getEventDetails();
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unathorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }

      this.loading = false;
    },
    async requestForSubscribe() {
      try {
        this.loading = true;
        const response = await EventService.subscribeOrUnsubscribe({
          eventId: this.eventId,
          userId: this.userId,
          isRequestedSubscribed: true,
          isRequestedUnsubscribed: false,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          await this.getEventDetails();
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async requestForUnsubscribe() {
      try {
        const response = await EventService.subscribeOrUnsubscribe({
          eventId: this.eventId,
          userId: this.userId,
          isRequestedSubscribed: false,
          isRequestedUnsubscribed: true,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          await this.getEventDetails();
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async previewImage(event) {
      if (event.length < 1) {
        (this.uploadImage = []), (this.uploadVideo = []);
        this.$refs.inputFile.reset();
      }
      var input = event;

      var result = [];

      for (let i = 0; i < input.length; i++) {
        result[i] = await toBase64(input[i]);
        // console.log(result[i]);
        if (
          [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "image/gif",
            "image/bmp",
          ].includes(input[i].type)
        ) {
          this.uploadImage[i] = {
            id: 0,
            virtualFileName: "",
            origionalFileName: input[i].name,
            eventMedia: result[i].split("base64,")[1],
            fileType: 0,
            isActive: true,
          };
        } else if (
          [
            "video/mpeg",
            "video/mpg",
            "video/avi",
            "video/wmv",
            "video/mov",
            "video/rm",
            "video/ram",
            "video/swf",
            "video/flv",
            "video/ogg",
            "video/webm",
            "video/mp4",
          ].includes(input[i].type)
        ) {
          this.uploadVideo[i] = {
            id: 0,
            virtualFileName: "",
            origionalFileName: input[i].name,
            eventMedia: result[i].split("base64,")[1],
            fileType: 0,
            isActive: true,
          };
        }

        // reader.readAsDataURL(input[i]);
      }

      (this.uploadImage = this.uploadImage.filter((element) => {
        if (Object.keys(element).length !== 0) {
          return true;
        }

        return false;
      })),
        (this.uploadVideo = this.uploadVideo.filter((element) => {
          if (Object.keys(element).length !== 0) {
            return true;
          }

          return false;
        }));

      // //  const result = await toBase64(selectedFile);
      // if (input.files) {
      //   var reader = new FileReader();
      //   reader.onload = (e) => {
      //     this.preview = e.target.result;
      //     this.backgroundImage = e.target.result.split("base64,")[1];
      //   };
      //   reader.readAsDataURL(input.files);

      //   // this.giftImageData = reader.result.split("base64,")[1];
      //   this.backgroundOrigionalFileName = input.files[0].name;
    },
    async deleteMedia(event) {
      this.ConfirmDialog = false;
      this.loading = true;
      try {
        if (event.status) {
          const response = await EventService.deleteMedia([
            {
              id: event.id,
            },
          ]);

          if (response.data.errorMessage == "") {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.data,
                type: "success",
              },
              { root: true }
            );
            this.getEventDetails();
          } else {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.errorMessage,
                type: "error",
              },
              { root: true }
            );
          }
        } else {
          this.ConfirmDialog = event.status;
        }
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unathorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
      this.ConfirmDialog = false;
      this.loading = false;
    },
    async downloadItinerary(item) {
      try {
        this.loading = true;
        const response = await ExportService.downloadItinerary({
          eventId: this.eventId,
          documentVirtualName: item.itineraryVirtualFileName,
          documentFileName: item.itineraryFileName,
        });
        if (response.status == 200) {
          const fileName = item.itineraryFileName;
          // .split('filename="')[1]
          // .split('";')[0]
          // .trim();
          byteToFileReader(response.data, fileName, "pdf");
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        // console.log(error);
        if (error.toJSON().status === 404 || error.toJSON().status === 400) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Document not Found",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    expendableVedioMethod(content, index) {
      this.expanded = true;
      this.imageSource = content;
      this.imgindex = index;
    },
    confimationDelete(id, value) {
      this.ConfirmDialog = value;
      this.deleteId = Number(id);
    },
    closeDisClaimer(event) {
      this.isDisclaimer = event.status;
      if (event.val) {
        localStorage.setItem("disclaimerSetting", {
          id: this.userId,
          value: event.val,
        });
      }
    },

    async downloadGuestSpeakerMedia(id, virtualName, fileName, type) {
      try {
        this.loading = true;
        const response = await EventService.downloadGuestSpeakerMedia({
          speakerMediaId: id,
          virtualName: virtualName,
          fileName: fileName,
        });
        if (response.status == 200) {
          const file = fileName;
          // .split('filename="')[1]
          // .split('";')[0]
          // .trim();
          byteToFileReader(response.data, file, type);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        // console.log(error);
        if (error.toJSON().status === 404 || error.toJSON().status === 400) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Document not Found",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    async guestItemDownloadList(guest, index) {
      (this.getDownloadList = {
        pdf: guest[index]?.eventSpeakerMedias?.eventPdf,
        image: guest[index]?.eventSpeakerMedias?.eventPhotos,
        videos: guest[index]?.eventSpeakerMedias?.eventVideos,
      }),
        await console.log(this.getDownloadList);
    },
  },
};
</script>
<style>
.image {
  width: 100%;
  max-width: 100%;
}
</style>
