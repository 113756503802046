<template>
  <div
    class="expandable-image"
    :class="{
      expanded: expanded,
    }"
  >
    <div class="container">
      <img
        :height="imageDimension.height"
        :width="imageDimension.width"
        :src="src"
      />
      <i v-if="expanded" class="close-button">
        <img src="@/assets/cross.svg" alt="ZoomOut" />
      </i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
      closeButtonRef: null,
      cloned: "",
      buttonMoveNext: null,
      buttonMovePrevious: null,
      imageHeight: 1,
      imageWidth: 1,
    };
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.fetchImageWidthHeight();
    this.expanded = true;
  },
  watch: {
    expanded(status) {
      this.$nextTick(() => {
        if (status) {
          this.cloned = this.$el;
          this.closeButtonRef = this.cloned.querySelector(".close-button");
          this.closeButtonRef.addEventListener("click", this.closeImage);

          document.body.appendChild(this.cloned);
          document.body.style.overflow = "hidden";
          this.cloned.addEventListener("touchmove", this.freezeVp, false);
          setTimeout(() => {
            this.cloned.style.opacity = 1;
          }, 0);
        } else {
          this.cloned.style.opacity = 0;
          this.cloned.removeEventListener("touchmove", this.freezeVp, false);
          setTimeout(() => {
            this.closeButtonRef.removeEventListener("click", this.closeImage);
            this.cloned.remove();
            this.cloned = null;
            this.closeButtonRef = null;
            document.body.style.overflow = "auto";
          }, 0);
        }
      });
    },
  },
  emits: ["slideToPrevious", "slideToNext", "closeZoom"],
  computed: {
    imageDimension() {
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      let height = 0;
      let width = 0;
      if (screenHeight / this.imageHeight < screenWidth / this.imageWidth) {
        height = this.imageHeight * (screenHeight / this.imageHeight);
        width = this.imageWidth * (screenWidth / this.imageWidth);
      } else {
        height = this.imageHeight * (screenWidth / this.imageWidth);
        width = this.imageWidth * (screenHeight / this.imageHeight);
      }
      return { height, width };
    },
  },
  methods: {
    closeImage() {
      this.$emit("closeZoom");
    },
    freezeVp(event) {
      event.preventDefault();
    },

    fetchImageWidthHeight() {
      let image = new Image();
      image.src = this.src;
      image.onload = function () {
        this.imageWidth = image.width;
        this.imageHeight = image.height;
      };
    },
  },
};
</script>
