<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="handleSubmit(importQualifiedAttendees)"
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">Import Qualified Attendees</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Import File"
                    rules="required"
                    mode="lazy"
                  >
                    <v-file-input
                      id="inputFile"
                      counter
                      label="Browse"
                      placeholder="Browse"
                      class="ml-md-3 mt-3 mt-md-0"
                      @change="previewPdf($event)"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      outlined
                      :show-size="1000"
                      :error-messages="errors"
                      :hide-details="!errors.length"
                    >
                    </v-file-input>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                $emit('disableDialog', { status: 'close', value: false }),
                  resetForm()
              "
            >
              Close
            </v-btn>

            <v-btn
              color="add-btn"
              @click="importQualifiedAttendees"
              text
              :disabled="loading"
              :loading="loading"
            >
              Import
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import EventService from "@/services/EventService";
import { toBase64 } from "../../utils/helpers";

export default {
  mounted() {},
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
    },
  },
  data: () => ({
    importFile: {},
    loading: false,
  }),

  methods: {
    async previewPdf(selectedFile) {
      const result = await toBase64(selectedFile);
      this.importFile = {
        eventId: this.eventId,
        excelData: result.split("base64,")[1],
        excelFileName: selectedFile.name,
      };
    },
    async importQualifiedAttendees() {
      try {
        this.loading = true;
        const response = await EventService.importQualifiedAttendees(
          this.importFile
        );
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          this.resetForm();

          this.$emit("disableDialog", { status: "close", value: false });
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }

        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 404) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Event attendees not found",
              type: "error",
            },
            { root: true }
          );
        }
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
    },
  },
};
</script>
<style scoped>
.glocation {
  border: 1px solid #a4a4a4;
  padding: 10px;
  margin-left: 7px;
  width: 94%;
  border-radius: 5px;
  font-size: 16px;
}
.parentClass.focus {
  color: #efce6a;
}
.parentClass {
  color: #a4a4a4;
}
.glocation:focus-visible,
.glocation:focus {
  border: 2px #efce6a solid !important;
  outline: none;
  box-shadow: none;
}
</style>
