<template>
  <v-col cols="12" md="12">
    <v-card class="pa-5 w-50 mx-auto">
      <h3>Subscription Form</h3>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col clos="12" md="12">
          <v-row class="my-0">
            <v-col cols="12" sm="6" class="">
              <v-text-field
                label="Name"
                class="form-control"
                placeholder="Name"
                required
                outlined
                hide-details
                :value="user.name"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="">
              <v-text-field
                label="Mobile"
                class="form-control"
                placeholder="Mobile"
                required
                outlined
                hide-details
                :value="acceptNumber(user.mobile)"
                @keypress="onlyNumbers"
                @input="user.mobile = $event.replaceAll('-', '')"
                hint="The Phone format is XXX-XXX-XXXX"
                maxlength="12"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="">
              <v-text-field
                label="Email"
                class="form-control"
                placeholder="Email"
                required
                outlined
                hide-details
                :value="user.emailId"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="">
              <v-text-field
                label="Address"
                class="form-control"
                placeholder="Address"
                required
                outlined
                hide-details
                :value="user.address"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="">
              <v-text-field
                label="Guest Name"
                class="form-control"
                placeholder="Guest Name"
                required
                outlined
                hide-details
                v-model="guestName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="">
              <v-text-field
                label="Special Requirement"
                class="form-control"
                placeholder="Special Requirement"
                required
                outlined
                hide-details
                v-model="specialRequirement"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="">
              <v-autocomplete
                :disabled="!isGiftAvailable"
                outlined
                dense
                chips
                small-chips
                label="Gift"
                hide-details
                v-model="giftId"
                :items="giftList"
                return-object
                item-text="giftName"
                item-value="giftId"
                :multiple="isMultiGift"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="">
              <div>
                <v-checkbox
                  v-model="checkBoxValue"
                  @change="openModal(checkBoxValue)"
                  hide-details
                  label="Change Contact for all Senior Life contact."
                  class="mt-1 mr-3 mb-3 mb-md-0"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                name="input-7-4"
                label="Comments"
                placeholder="Comments"
                v-model="comments"
              ></v-textarea>
              <div class="text-right">
                <v-btn
                  class="theme-btn-gold"
                  @click="$emit('cancelsubscribe', true)"
                  >cancel</v-btn
                >&nbsp;
                <v-btn
                  class="theme-btn-gold"
                  @click="subscribe"
                  :disabled="!accessEnableAdd"
                  :loading="loading"
                  >Save</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <UpdateSubscribe
      :dialog="dialog"
      :dataReceived="detail"
      @disableDialog="closeModal"
      @updateDetails="updateUserDetail($event)"
    />
  </v-col>
</template>
<script>
import EventService from "../services/EventService";
import UpdateSubscribe from "../components/Modals/UpdateSubscribe.vue";
import { actionEnable } from "../utils/helpers";

export default {
  components: {
    UpdateSubscribe,
  },
  computed: {
    accessEnableAdd() {
      return actionEnable("EventAttendee", "ApplyEvent", 1);
    },
  },
  props: {
    isMultiGift: {
      type: Boolean,
      default: false,
    },
    isGiftAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      giftList: [],
      user: {},
      detail: {},
      dialog: false,
      guestName: "",
      giftId: [],
      comments: "",
      specialRequirement: "",
      eventId: 0,
      checkBoxValue: false,
    };
  },
  mounted() {
    this.eventId = this.$route.query.eventId;
    this.giftByEvent();
    this.assignValue();
  },
  methods: {
    openModal(event) {
      if (event) this.dialog = true;
      else if (!event) {
        this.assignValue();
      }
    },
    closeModal(event) {
      this.dialog = event.value;
      if (event.status === "close") {
        this.checkBoxValue = false;
        this.assignValue();
      }
    },
    assignValue() {
      const userDetail = this.$store.state.auth.userState.user;
      this.user.name = userDetail.userName;
      this.user.emailId = userDetail.email;
      this.user.address = userDetail.address;
      this.user.mobile = userDetail.phone;
      this.detail = {
        username: this.user.name,
        email: this.user.emailId,
        address: this.user.address,
        mobile: this.user.mobile,
      };
      this.checkBoxValue = false;
    },
    async giftByEvent() {
      try {
        const { data } = await EventService.giftByEvent(this.eventId);
        this.giftList = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    acceptNumber(value) {
      if (value !== null && value !== "" && value !== 0) {
        const x = String(value)
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2]
          ? `${x[1]}`
          : `${x[1]}-${x[2]}${x[3] ? `${"-" + x[3]}` : ""}`;
      }
    },
    onlyNumbers(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    updateUserDetail(event) {
      this.user.name = event.name;
      this.user.emailId = event.emailId;
      this.user.address = event.address;
      this.user.mobile = event.mobile;
    },
    async subscribe() {
      this.loading = true;
      let giftId = [];
      try {
        if (this.isMultiGift === false && this.giftId.giftId != null) {
          giftId.push(this.giftId.giftId);
        } else {
          giftId = this.giftId.map((item) => {
            return item.giftId;
          });
        }

        const response = await EventService.subscribe({
          id: 0,
          eventId: Number(this.eventId),
          name: this.user.name,
          email: this.user.emailId,
          address: this.user.address,
          phoneNumber: this.user.mobile,
          requestedGiftId: giftId,
          guestName: this.guestName,
          specialRequirement: this.specialRequirement,
          comments: this.comments,
          changeSRLifeContact: true,
        });
        if (!response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );

          this.$emit("subscriptionComplete", true);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>
