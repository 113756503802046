<template>
  <master-layout>
    <div class="mb-3 text-right">
      <div class="flex-md-row d-sm-flex justify-space-between">
        <div col="12" sm="8">
          <v-select
            v-model="eventId"
            :items="getActiveEventList"
            item-value="id"
            item-text="name"
            label="Select Event"
            outlined
            dark
            @change="getAttendeesAndNonAttendees()"
            prepend-icon="mdi-account-box-multiple-outline"
            hide-details=""
          >
          </v-select>
        </div>
      </div>
    </div>
    <v-card>
      <v-row class="ma-0">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            Attendees Management
            <!-- <v-spacer></v-spacer> -->
          </v-card-title>
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            max-width="400px"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" sm="8">
          <h3>Requested to Unsubscribe</h3>
        </v-col>
      </v-row>
      <v-data-table
        item-key="id"
        :headers="registerHeader"
        :items="registerHeaderForAttendees"
        class="elevation-1 mytable"
        :loading="loading"
        must-sort
        :search="search"
        @drop="onDrop(true, false)"
      >
        <template v-slot:item="{ item }">
          <tr draggable="true" @dragstart="updateToSubscribed($event, item)">
            <td>{{ item.userName }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.email }}</td>
            <td>
              {{
                item.phoneNumber
                  .replace(/\D+/g, "")
                  .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "$1-$2-$3")
              }}
            </td>
            <td>{{ item.roleName }}</td>

            <td>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="color: #f0ce6a; background-color: transparent"
                    @click="onDrop(item, false, true)"
                    ><v-icon>mdi-arrow-down-bold</v-icon></v-btn
                  > </template
                >Move to Unsubscribe</v-tooltip
              >
            </td>
          </tr>
        </template>
        <!-- <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                :disabled="item.imgByte === null"
                v-bind="attrs"
                v-on="on"
                @click="addOrUpdateHotel('update', item)"
              >
                <v-icon color="primary">mdi-pencil</v-icon></v-btn
              >
            </template>
            Edit Hotel
          </v-tooltip>
        </template> -->
      </v-data-table>
      <v-row class="ma-0">
        <v-col cols="12" sm="8">
          <h3>Requested to subscribe</h3>
        </v-col>
      </v-row>
      <v-data-table
        item-key="id"
        :headers="registerHeader"
        :items="registerHeaderForNonAttendees"
        class="elevation-1 mytable"
        :loading="loading"
        must-sort
        :search="search"
        @drop="onDrop(false, true)"
      >
        <template v-slot:item="{ item }">
          <tr draggable="true" @dragstart="updateToSubscribed($event, item)">
            <td>{{ item.userName }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.email }}</td>
            <td>
              {{
                item.phoneNumber
                  .replace(/\D+/g, "")
                  .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "$1-$2-$3")
              }}
            </td>
            <td>{{ item.roleName }}</td>

            <td>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    dark
                    style="color: #f0ce6a; background-color: transparent"
                    v-bind="attrs"
                    v-on="on"
                    @click="onDrop(item, true, false)"
                    ><v-icon>mdi-arrow-up-bold</v-icon></v-btn
                  >
                </template>
                Move to Subscribe</v-tooltip
              >
            </td>
          </tr>
        </template>

        <!-- <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                :disabled="item.imgByte === null"
                v-bind="attrs"
                v-on="on"
                @click="addOrUpdateHotel('update', item)"
              >
                <v-icon color="primary">mdi-pencil</v-icon></v-btn
              >
            </template>
            Edit Hotel
          </v-tooltip>
        </template> -->
      </v-data-table>
    </v-card>
  </master-layout>
</template>
<script>
import EventService from "../services/EventService.js";
import { phoneMasking, actionEnable } from "../utils/helpers";
export default {
  components: {},
  name: "ReportsManagement",

  data: () => ({
    loading: false,
    getActiveEventList: [],
    roleName: "",
    eventId: 0,
    moveData: {},
    userId: 0,
    registerHeader: [
      { text: "Name", value: "userName", width: 120 },
      { text: "Address", value: "address", width: 150 },
      { text: "Email", value: "email", width: 150 },
      { text: "Mobile", value: "phoneNumber", width: 100 },
      { text: "Role", value: "roleName", width: 100 },
      { text: "Action", value: "action", width: 70, sortable: false },
    ],

    registerHeaderForAttendees: [],
    registerHeaderForNonAttendees: [],
    search: "",
  }),
  async mounted() {
    // this.roleName = this.$store.state.auth.userState.roleName;
    // // console.log(this.roleName);
    const getActiveEvent = await EventService.getActiveEvents();
    this.getActiveEventList = getActiveEvent.data.data;

    this.getAttendeesAndNonAttendees();
  },
  computed: {
    accessEnableExport() {
      return actionEnable("EventRegisteredReport", "ExportEventRegistered", 1);
    },
  },
  methods: {
    updateToSubscribed(event, item) {
      let data = {
        userId: item.userId,
        eventId: this.eventId,
        isSubscribeAllowed: true,
        isUnsubscribe: false,
      };
      this.moveData = data;
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemId", item.userId);
    },
    async onDrop(item, sub, unsub) {
      this.loading = true;
      try {
        const response = await EventService.updateToSubscribed({
          userId: item.userId,
          eventId: this.eventId,
          isSubscribeAllowed: sub,
          isUnsubscribe: unsub,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          this.getAttendeesAndNonAttendees();
          this.loading = false;
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async getAttendeesAndNonAttendees() {
      try {
        this.loading = true;
        const response = await EventService.getEventAttendeesAndNonAttendees(
          this.eventId
        );

        this.registerHeaderForAttendees =
          response.data.data.requestedUnsubscribe;
        this.registerHeaderForNonAttendees =
          response.data.data.requestedSubscribe;
        if (response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    phoneMask(value) {
      phoneMasking(value);
    },
  },
};
</script>

<style scoped></style>
