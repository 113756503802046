<template>
  <master-layout>
    <event-detail v-if="subscribe" @subscribe="addSubscriber($event)" />
    <access
      v-if="isCodeRequired && !subscribe"
      @cancelsubscribe="subscribe = $event"
      @confirmAccessCode="isCodeRequired = $event"
    />
    <Subscribe
      v-if="!isCodeRequired && !subscribe"
      @cancelsubscribe="subscribe = $event"
      :isMultiGift="isMultiGift"
      :isGiftAvailable="isGiftAvailable"
      @subscriptionComplete="subscribe = $event"
    />
  </master-layout>
</template>
<script>
import Access from "../../components/Access.vue";
import EventDetail from "../../components/EventDetail.vue";
import Subscribe from "../../components/Subscribe.vue";
export default {
  components: { EventDetail, Access, Subscribe },
  data() {
    return {
      subscribe: true,
      eventId: 0,
      isMultiGift: false,
      isCodeRequired: false,
      isGiftAvailable: false,
    };
  },
  methods: {
    addSubscriber(event) {
      this.subscribe = event.isSubscribe;
      this.eventId = event.eventId;
      this.isCodeRequired = event.isCodeRequired;
      this.isGiftAvailable = event.isGiftAvailable;
      this.isMultiGift = event.isMultiGift;
    },
  },
};
</script>
