<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title primary-title
        ><v-icon>mdi-alert-circle-outline</v-icon>Disclaimer
      </v-card-title>
      <v-card-text
        ><p style="text-align: justify">
          Senior Life Insurance Company reserves the right to use any
          photograph/video taken or uploaded at any event, without the expressed
          written permission of those included within the photograph/video.
          Senior Life Insurance Company may use the photograph/video in
          publications or other media material including but not limited to:
          brochures, invitations, books, newspapers, magazines, television,
          websites, social media, etc.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-checkbox
          label="Don't show this message again"
          v-model="status"
        ></v-checkbox
        ><v-spacer></v-spacer>
        <v-btn
          @click="$emit('closeDisclaimer', { status: false, val: status })"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  mounted() {},
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    id: 0,
    name: "",
    status: false,
    actionBy: 1,
    loading: false,
  }),
};
</script>
