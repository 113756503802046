<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="handleSubmit(updateDetail)"
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">Update Information</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Name"
                      v-model="name"
                      class="form-control"
                      placeholder="Name"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-account"
                      disabled
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Email"
                      v-model="emailId"
                      class="form-control"
                      placeholder="Email"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-account-box"
                      :disabled="loading"
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Phone Number"
                      class="form-control"
                      placeholder="Phone Number"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-phone"
                      :disabled="loading"
                      data-vv-validate-on="change"
                      :value="acceptNumber(mobile)"
                      @keypress="onlyNumbers"
                      @input="mobile = $event.replaceAll('-', '')"
                      hint="The Phone format is XXX-XXX-XXXX"
                      maxlength="12"
                    ></v-text-field
                  ></validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Address"
                      v-model="address"
                      class="form-control"
                      placeholder="Address"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-map-marker"
                      :disabled="loading"
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('disableDialog', { status: 'close', value: false })"
            >
              Close
            </v-btn>

            <v-btn
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  watch: {
    dataReceived: {
      handler: function (newVal) {
        if (newVal) {
          this.name = this.dataReceived.username;
          this.emailId = this.dataReceived.email;
          this.address = this.dataReceived.address;
          this.mobile = this.dataReceived.mobile;
        }
      },
      deep: true,
    },
  },
  mounted() {},
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
  },
  data: () => ({
    id: 0,
    name: "",
    emailId: "",
    address: "",
    mobile: "",
    loading: false,
  }),

  methods: {
    updateDetail() {
      this.$emit("updateDetails", {
        name: this.name,
        emailId: this.emailId,
        address: this.address,
        mobile: this.mobile,
      });
      this.$emit("disableDialog", { status: "open", value: false });
    },
    acceptNumber(value) {
      if (value !== null && value !== "" && value !== 0) {
        const x = String(value)
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2]
          ? `${x[1]}`
          : `${x[1]}-${x[2]}${x[3] ? `${"-" + x[3]}` : ""}`;
      }
    },
    onlyNumbers(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
      this.$refs.modalForm.reset();
    },
  },
};
</script>
<style scoped>
.glocation {
  border: 1px solid #a4a4a4;
  padding: 10px;
  margin-left: 7px;
  width: 94%;
  border-radius: 5px;
  font-size: 16px;
}
.parentClass.focus {
  color: #efce6a;
}
.parentClass {
  color: #a4a4a4;
}
.glocation:focus-visible,
.glocation:focus {
  border: 2px #efce6a solid !important;
  outline: none;
  box-shadow: none;
}
</style>
