<template>
  <v-col cols="12" md="12">
    <v-card class="pa-5 w-50 mx-auto">
      <h3></h3>

      <v-row>
        <v-col clos="12" md="12">
          <v-row class="my-0">
            <v-col cols="12" class="">
              <h4 class="text-center">Enter Email</h4>
              <v-divider class="my-3"></v-divider>
              <v-text-field
                label="Email"
                class="form-control"
                placeholder="Email"
                required
                outlined
                hide-details
                :value="user.emailId"
                :disabled="user.emailId ? true : false"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <div class="text-right">
                <v-btn class="theme-btn" @click="$emit('cancelsubscribe', true)"
                  >cancel</v-btn
                >&nbsp;
                <v-btn
                  class="theme-btn-gold"
                  @click="generateAccessCode"
                  :disabled="enableAccesGenerateCode"
                  >Send</v-btn
                >
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="">
              <h4 class="text-center">Enter Code</h4>

              <v-divider class="my-3"></v-divider>
              <v-text-field
                label="Code"
                class="form-control"
                placeholder="Code"
                required
                outlined
                hide-details
                v-model="user.code"
                :disabled="!message || loading"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <div class="text-right">
                <v-btn
                  class="theme-btn-gold"
                  :disabled="!user.code || enableAccessVerifyCode"
                  @click="validateAccessCode"
                  >Verify Code</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>
<script>
import EventService from "../services/EventService";
import { actionEnable } from "../utils/helpers";
export default {
  data() {
    return { user: [], eventid: 0, message: false, loading: false };
  },
  created() {
    this.eventId = Number(this.$route.query.eventId);
    this.assignValue();
  },
  computed: {
    enableAccesGenerateCode() {
      return !actionEnable("EventAttendee", "GenerateAccessCode", 1);
    },
    enableAccessVerifyCode() {
      return !actionEnable("EventAttendee", "ValidateAccessCode", 1);
    },
  },
  methods: {
    assignValue() {
      const userDetail = this.$store.state.auth.userState.user;
      this.user.name = userDetail.userName;
      this.user.emailId = userDetail.email;
      this.user.address = userDetail.address;
      this.user.mobile = userDetail.phone;
    },
    async validateAccessCode() {
      try {
        this.loading = true;
        const accessCode = await EventService.validateAccessCode({
          eventId: this.eventId,
          accessCode: this.user.code,
          email: this.user.emailId,
        });

        if (accessCode.data.errorMessage == "") {
          this.message = true;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: accessCode.data.data,
              type: "success",
            },
            { root: true }
          );
          this.loading = false;
          this.$emit("confirmAccessCode", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: accessCode.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async generateAccessCode() {
      try {
        const accessCode = await EventService.generateAccessCode({
          eventId: this.eventId,
          email: this.user.emailId,
        });
        if (accessCode.data.errorMessage == "") {
          this.message = true;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: accessCode.data.data,
              type: "success",
            },
            { root: true }
          );

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: accessCode.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
