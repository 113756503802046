import http from "./http-client";

class ExportService {
  exportEventAttendees(payload) {
    return http.post("/EventRegisteredReport/ExportEventAttendees", payload, {
      responseType: "blob",
    });
  }
  exportQualifiedAttendees(payload) {
    return http.post(
      "/EventRegisteredReport/ExportEventAttendeesGiftSelection",
      payload,
      {
        responseType: "blob",
      }
    );
  }
  exportEventDetails(payload) {
    return http.post("/EventRegisteredReport/ExportEventDetails", payload, {
      responseType: "blob",
    });
  }
  downloadItinerary(payload) {
    return http.post("/Event/DownloadItinerary", payload, {
      responseType: "blob",
    });
  }
}

export default new ExportService();
